// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-beyond-the-talk-js": () => import("./../../../src/pages/beyond-the-talk.js" /* webpackChunkName: "component---src-pages-beyond-the-talk-js" */),
  "component---src-pages-chatbot-js": () => import("./../../../src/pages/chatbot.js" /* webpackChunkName: "component---src-pages-chatbot-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

